<template>
    <div class="about">
        <div class="about1">
            <div class="texts">
                <div class="t1">节点小宝-远程访问专家</div>
                <div class="t2">赋能支持远程开发、远程剪辑、远程办公及远程桌面等多种场景。</div>
            </div>
        </div>
        <div class="about2">
            <div class="texts">
                <div class="texts-c">
                    <div class="t1">关于我们</div>
                    <div class="t2"></div>
                    <div class="t3">深圳市玩物科技有限公司成立于2017年，公司总部位于粤港澳大湾区的深圳，是一家国家高新技术企业和专精特新企业。核心成员均来自知名互联网公司，工作经验平均超10年以上，具有丰富的项目管理及运营经验。</div>
                    <div class="t3 t4">玩物科技公司围绕产品赋能、技术授权、算力服务为发展方向，帮助企业远程办公、家庭远程打印、政企安全连接传输提供产品解决方案和技术解决方案。</div>
                    <div class="t3 t4">经过多年发展，公司产品和技术受到了用户和客户的喜欢，合作伙伴涵盖私有云行业、路由器行业、视频行业、运营商、监控行业等。踏实而又富于激情的团队，对待客户及用户需求具备快速洞察力和响应力，我们的发展宗旨是成长为一家领先的网络安全连接及算力服务商。</div>
                </div>
            </div>
        </div>
        <div class="about3">
            <div class="texts">
                <div class="t1">企业荣誉</div>
                <div class="t2 t2a">
                    <div class="t3">2021 <span class="year">年</span></div>
                    <div class="t4">国家高新企业</div>
                </div>
                <div class="t2 t2b">
                    <div class="t3">2021 <span class="year">年</span></div>
                    <div class="t4">国家鼓励的软件企业</div>
                </div>
                <div class="t2 t2c">
                    <div class="t3">2022 <span class="year">年</span></div>
                    <div class="t4">创新型中小企业</div>
                </div>
                <div class="t2 t2d">
                    <div class="t3">2023 <span class="year">年</span></div>
                    <div class="t4">专精特新企业</div>
                </div>
            </div>
        </div>
        <div class="about4">
            <div class="t1">合作伙伴</div>
            <div class="t2">我们的团队得到了这样的优秀公司的支持</div>
            <div class="t3"></div>
            <div class="t3 t3b"></div>
            <div class="t3 t3c"></div>
            <div class="t3 t3d"></div>
            <div class="t3 t3e"></div>
        </div>
        <div class="about5">
            <div class="t1">联系我们</div>
            <div class="t2">
                <div class="t2a">公司地址</div>
                <div class="t2b">深圳市南山区西丽街道创智云城A7-2302</div>
            </div>
            <div class="t2 t3">
                <div class="t2a">咨询电话</div>
                <div class="t2b">0755-86607260</div>
            </div>
        </div>
        <div class="i12">
            <div class="t1-c">
                <div class="t1">远程访问用小宝，您的远程访问专家。</div>
                <div class="btn" @click="jumpDownload">立即下载</div>
            </div>
        </div>
        <Foot></Foot>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        name: "About",
        components: {
            Foot,
        },
        methods:{
            jumpDownload: function () {
                this.$router.push({
                    path: "download",
                });
            },
        }
    }
</script>

<style lang="less">
.about{
    .about1{
        height: 500px;
        background-image: url("../assets/v4/a1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        .texts{
            margin: 0 auto;
            margin-top: 168px;
            width: 1280px;
            .t1{
                font-size: 48px;
                font-weight: bold;
                color: #17171B;
                line-height: 70px;
            }
            .t2{
                margin-top: 12px;
                font-size: 20px;
                color: #17171B;
                line-height: 28px;
            }
        }

    }
    .about2{
        height: 600px;
        .texts{
            margin: 0 auto;
            width: 1280px;
            height: 100%;
            background-image: url("../assets/v4/a2.png");
            background-size: 600px 400px;
            background-repeat: no-repeat;
            background-position: left center;
            .texts-c{
                float: right;
                margin-top: 124px;
                width: 614px;
                .t1{
                    font-size: 30px;
                    font-weight: bold;
                    color: #17171B;
                    height: 42px;
                }
                .t2{
                    margin-top: 20px;
                    width: 31px;
                    height: 4px;
                    border-radius: 3px;
                    background: #4E4CFF;
                }
                .t3{
                    margin-top: 20px;
                    color: #57576C;
                    font-size: 16px;
                    line-height: 24px;
                }
                .t4{
                    margin-top: 26px;
                }
            }
        }

    }
    .about3 {
        height: 600px;
        background: linear-gradient(90deg, #ECECFF 0%, #F1EDFF 50%, #FFF4ED 100%);
        .texts {
            margin: 0 auto;
            height: 100%;
            overflow: hidden;
            text-align: center;

            .t1{
                margin-top: 66px;
                margin-bottom: 50px;
                font-size: 40px;
                font-weight: bold;
                color: #17171B;
                line-height: 58px;
                text-align: center;
            }
            .t2{
                display: inline-block;
                position: relative;
                margin: 0 13px;
                padding-left: 24px;
                width: 300px;
                height: 300px;
                border-radius: 10px;
                background: linear-gradient(180deg, rgba(230, 227, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
                box-sizing: border-box;
                border: 2px solid #FFFFFF;
                box-shadow: 0px 4px 30px 0px #E6E3FF;
                overflow: hidden;
                text-align: left;
                .t3{
                    margin-top: 220px;
                    color: #17171B;
                    line-height: 30px;
                    font-weight: 700;
                    font-size: 26px;
                    .year{
                        margin-left: -4px;
                        vertical-align: middle;
                        font-weight: 700;
                        font-size: 16px;
                    }
                }
                .t4{
                    margin-top: 8px;
                    font-size: 18px;
                    color: #17171B;
                    line-height: 20px;
                }
            }
            .t2::before{
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 200px;
                background-image: url("../assets/v4/a3.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            .t2b::before{
                background-image: url("../assets/v4/a4.png");
            }
            .t2c::before{
                background-image: url("../assets/v4/a5.png");
            }
            .t2d::before{
                background-image: url("../assets/v4/a6.png");
            }
        }
    }
    .about4{
        height: 420px;
        background: #FFFFFF;
        overflow: hidden;
        text-align: center;
        .t1{
            margin-top: 66px;
            font-size: 40px;
            font-weight: bold;
            color: #17171B;
            line-height: 58px;
            text-align: center;
        }
        .t2{
            margin-top: 10px;
            font-size: 16px;
            line-height: 22px;
            color: #57576C;
            text-align: center;
        }
        .t3{
            display: inline-block;
            margin: 0 10px;
            margin-top: 50px;
            width: 240px;
            height: 100px;
            border-radius: 10px;
            box-shadow: 0px 4px 20px 0px #E8ECF9;
            background-image: url("../assets/v4/a7.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        .t3b{
            background-image: url("../assets/v4/a8.svg");
        }
        .t3c{
            background-image: url("../assets/v4/a9.svg");
        }
        .t3d{
            background-image: url("../assets/v4/a10.svg");
        }
        .t3e{
            background-image: url("../assets/v4/a11.svg");
        }
    }
    .about5{
        height: 420px;
        background: #F6F8FF;
        overflow: hidden;
        text-align: center;
        .t1{
            margin-top: 66px;
            font-size: 40px;
            font-weight: bold;
            color: #17171B;
            line-height: 58px;
            text-align: center;
        }
        .t2{
            margin: 0 20px;
            margin-top: 58px;
            display: inline-block;
            position: relative;
            width: 620px;
            height: 140px;
            border-radius: 10px;
            background: #FFFFFF;
            box-shadow: 0px 4px 30px 0px #E8ECF9;
            text-align: left;
            overflow: hidden;
            .t2a{
                margin-top: 32px;
                margin-left: 132px;
                font-size: 24px;
                font-weight: 500;
                line-height: 35px;
                color: #17171B;
            }
            .t2b{
                margin-left: 132px;
                margin-top: 8px;
                font-size: 20px;
                color: #57576C;
                line-height: 28px;
            }
        }
        .t2::before{
            position: absolute;
            top: 34px;
            left: 40px;
            content: "";
            width: 72px;
            height: 72px;
            background-image: url("../assets/v4/a12.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        .t3::before{
            background-image: url("../assets/v4/a13.svg");
        }

    }
    .i12 {
        width: 100%;
        height: 160px;
        background-image: url("../assets/v5/bgtry.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;

        .t1-c {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            height: 160px;
            overflow: hidden;
            .t1 {
                font-size: 36px;
                line-height: 160px;
                font-weight: 500;
                color: #ffffff;
                -webkit-text-stroke: 1px #ffffff;
            }
            .t2 {
                margin-top: 6px;
                font-size: 16px;
                font-weight: bold;
                line-height: 30px;
                color: #ffffff;
            }
            .btn {
                position: absolute;
                top: 58px;
                right: 0;
                width: 140px;
                height: 44px;
                line-height: 44px;
                border-radius: 6px;
                background-color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                color: #3772FF;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
</style>