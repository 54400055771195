var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"i1"},[_c('div',{staticClass:"text1"},[_vm._v("节点小宝 - 远程访问专家")]),_c('div',{staticClass:"text2"},[_vm._v("极速 安全 稳定 免费")]),_c('div',{staticClass:"gt"},[_c('div',{staticClass:"text3"},[_vm._v("可远程访问")]),_c('span',{staticClass:"gradient-text",class:_vm.classComputed},[_vm._v(_vm._s(_vm.text))])]),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.jumpDownload()}}},[_vm._v("立即体验")]),_vm._m(0),_c('div',{staticClass:"text4"},[_vm._v("合二为一")]),_c('div',{staticClass:"text5"},[_vm._v("更简单、更快速、更可靠")]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"i10"},[_c('div',{staticClass:"t1"},[_vm._v("来自真实用户的评价")]),_c('div',{staticClass:"text2"},[_vm._v("因卓越的速度，获得了众多用户的喜爱与信赖")]),_c('div',{staticClass:"t2-cc"},[_c('div',{staticClass:"t2-c"},_vm._l((_vm.pjArr),function(item,index){return _c('div',{staticClass:"item"},[_c('img',{staticClass:"item-img",attrs:{"src":item.imgsrc}}),_c('div',{staticClass:"item-t1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-t2"},[_vm._v(_vm._s(item.pj))])])}),0)])]),_c('div',{staticClass:"i12"},[_c('div',{staticClass:"t1-c"},[_c('div',{staticClass:"t1"},[_vm._v("远程访问用小宝，您的远程访问专家。")]),_c('div',{staticClass:"btn",on:{"click":_vm.jumpDownload}},[_vm._v("立即下载")])])]),_c('Foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-download"},[_c('a',{staticClass:"btn-d",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?device=nas&brand=synology"}},[_c('span',{staticClass:"btn-img btn-img1"}),_vm._v("Nas")]),_c('a',{staticClass:"btn-d btn-d2",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?device=windows"}},[_c('span',{staticClass:"btn-img btn-img2"}),_vm._v("Windows")]),_c('a',{staticClass:"btn-d btn-d3",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?device=mac"}},[_c('span',{staticClass:"btn-img btn-img3"}),_vm._v("macOS")]),_c('a',{staticClass:"btn-d btn-d4",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?client=Android"}},[_c('span',{staticClass:"btn-img btn-img4"}),_vm._v("Android")]),_c('a',{staticClass:"btn-d btn-d5",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?client=ios"}},[_c('span',{staticClass:"btn-img btn-img5"}),_vm._v("iOS")]),_c('a',{staticClass:"btn-d btn-d6",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?device=docker"}},[_c('span',{staticClass:"btn-img btn-img6"}),_vm._v("Docker")]),_c('a',{staticClass:"btn-d btn-d7",attrs:{"target":"_blank","href":"https://iepose.com/jdxb/pc/download?device=openwrt"}},[_c('span',{staticClass:"btn-img btn-img7"}),_vm._v("OpenWrt")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text6"},[_c('span',{staticClass:"text7"},[_vm._v("内网穿透")]),_vm._v("与"),_c('span',{staticClass:"text7"},[_vm._v("异地组网")]),_vm._v("完美融合，体验超凡连接能力，助你实现远程访问自由")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i2"},[_c('div',{staticClass:"text1"},[_vm._v("让网络互联变得简单易用")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text2"},[_vm._v("P2P穿透技术传输更极致")]),_c('p',{staticClass:"text3"},[_vm._v(" 即装即用的远程访问，支持内网穿透和异地组网。具备超快的连接速度，轻松组建安全可信的专用网。基于P2P点对点技术实现互联互通。应用场景：远程开发、远程剪辑、管理NAS设备、访问企业办公系统、Docker、SSH、RDP桌面、挂载共享文件。 ")]),_c('div',{staticClass:"more"},[_c('div',{staticClass:"more1"},[_vm._v(" 部署简单 ")]),_c('div',{staticClass:"more1 more2"},[_vm._v(" 安全稳定 ")]),_c('div',{staticClass:"more1 more3"},[_vm._v(" 服务保障 ")]),_c('div',{staticClass:"more1 more4"},[_vm._v(" 随时管理 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i2 i3"},[_c('div',{staticClass:"text1"},[_vm._v("内网穿透 轻松回内网")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text2"},[_vm._v("不受网络限制，通过域名访问内网")]),_c('p',{staticClass:"text3"},[_vm._v(" 自定义域名访问内网服务，支持身份认证、网络认证、设备鉴定、加密传输的安全策略。可适应复杂的网络环境和防火墙，采用自主研发的 CSDPI（Client Service Data Interconnection）大并发请求访问技术，保障快速访问到内网服务。 ")]),_c('div',{staticClass:"more"},[_c('div',{staticClass:"more1"},[_vm._v(" 快速部署 ")]),_c('div',{staticClass:"more1 more2"},[_vm._v(" 安全稳定 ")]),_c('div',{staticClass:"more1 more3"},[_vm._v(" 可靠保障 ")]),_c('div',{staticClass:"more1 more4"},[_vm._v(" 低延时 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i4"},[_c('div',{staticClass:"home-item4-c"},[_c('div',{staticClass:"t1"},[_vm._v("异地组网 一台安装服务全网")]),_c('div',{staticClass:"text2"},[_vm._v("不用每台设备都安装 通过中转连接更多设备")]),_c('div',{staticClass:"t2"},[_c('div',{staticClass:"title1"},[_vm._v("点对点组网")]),_c('div',{staticClass:"title2"},[_vm._v("两端登录全自动组网")]),_c('div',{staticClass:"t2-img t2-img2"})]),_c('div',{staticClass:"t2 t2a"},[_c('div',{staticClass:"title1"},[_vm._v("全网映射组网")]),_c('div',{staticClass:"title2"},[_vm._v("将远程局域网映射到本机")]),_c('div',{staticClass:"t2-img"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i9"},[_c('div',{staticClass:"t1"},[_vm._v("零信任安全保障")]),_c('div',{staticClass:"text2"},[_vm._v("端对端私有协议 一次一密安全连接")]),_c('div',{staticClass:"t2-c"},[_c('div',{staticClass:"t2"},[_c('div',{staticClass:"t3"},[_vm._v("三重认证")]),_c('div',{staticClass:"t4"},[_vm._v("用户认证")]),_c('div',{staticClass:"t4"},[_vm._v("设备认证")]),_c('div',{staticClass:"t4"},[_vm._v("链路认证")])]),_c('div',{staticClass:"t2 t2a"},[_c('div',{staticClass:"t3"},[_vm._v("动态密钥")]),_c('div',{staticClass:"t4"},[_vm._v("一次一密")]),_c('div',{staticClass:"t4"},[_vm._v("即时协商")]),_c('div',{staticClass:"t4"},[_vm._v("256位加密")])]),_c('div',{staticClass:"t2 t2b"},[_c('div',{staticClass:"t3"},[_vm._v("动态域名")]),_c('div',{staticClass:"t4"},[_vm._v("即时生成")]),_c('div',{staticClass:"t4"},[_vm._v("立即生效")]),_c('div',{staticClass:"t4"},[_vm._v("延时销毁")])]),_c('div',{staticClass:"t2 t2c"},[_c('div',{staticClass:"t3"},[_vm._v("来源限定")]),_c('div',{staticClass:"t4"},[_vm._v("智能识别")]),_c('div',{staticClass:"t4"},[_vm._v("攻击阻断")]),_c('div',{staticClass:"t4"},[_vm._v("风控报警")])]),_c('div',{staticClass:"t2 t2d"},[_c('div',{staticClass:"t3"},[_vm._v("链路保障")]),_c('div',{staticClass:"t4"},[_vm._v("可信认证")]),_c('div',{staticClass:"t4"},[_vm._v("加密传输")]),_c('div',{staticClass:"t4"},[_vm._v("延时关闭")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"i6"},[_c('div',{staticClass:"home-item6-c"},[_c('div',{staticClass:"t1"},[_vm._v("用节点小宝 体验超凡连接")]),_c('div',{staticClass:"t2"},[_vm._v("智慧互联 易用快速")]),_c('div',{staticClass:"t3"},[_vm._v(" 十分期待您来体验产品，无论您是远程开发、远程剪辑、远程下载、远程管理。 节点小宝超凡的互联传输，助您置身快感之中。 ")])])])
}]

export { render, staticRenderFns }