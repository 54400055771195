<template>
  <div class="home">
    <div class="i1">
      <div class="text1">节点小宝 - 远程访问专家</div>
      <div class="text2">极速  安全  稳定  免费</div>
      <div class="gt">
        <div class="text3">可远程访问</div>
        <span class="gradient-text" v-bind:class="classComputed">{{text}}</span>
      </div>
      <div class="btn" @click="jumpDownload()">立即体验</div>
      <div class="btn-download">
        <a
                class="btn-d"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology"
        ><span class="btn-img btn-img1"></span>Nas</a
        >
        <a
                class="btn-d btn-d2"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=windows"
        ><span class="btn-img btn-img2"></span>Windows</a
        >
        <a
                class="btn-d btn-d3"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=mac"
        ><span class="btn-img btn-img3"></span>macOS</a
        >
        <a
                class="btn-d btn-d4"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?client=Android"
        ><span class="btn-img btn-img4"></span>Android</a
        >
        <a
                class="btn-d btn-d5"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?client=ios"
        ><span class="btn-img btn-img5"></span>iOS</a
        >
        <a
                class="btn-d btn-d6"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=docker"
        ><span class="btn-img btn-img6"></span>Docker</a
        >
        <a
                class="btn-d btn-d7"
                target="_blank"
                href="https://iepose.com/jdxb/pc/download?device=openwrt"
        ><span class="btn-img btn-img7"></span>OpenWrt</a
        >
      </div>
      <div class="text4">合二为一</div>
      <div class="text5">更简单、更快速、更可靠</div>
      <div class="text6"><span class="text7">内网穿透</span>与<span class="text7">异地组网</span>完美融合，体验超凡连接能力，助你实现远程访问自由</div>
    </div>
    <div class="i2">
      <div class="text1">让网络互联变得简单易用</div>
      <div class="content">
        <div class="text2">P2P穿透技术传输更极致</div>
        <p class="text3">
          即装即用的远程访问，支持内网穿透和异地组网。具备超快的连接速度，轻松组建安全可信的专用网。基于P2P点对点技术实现互联互通。应用场景：远程开发、远程剪辑、管理NAS设备、访问企业办公系统、Docker、SSH、RDP桌面、挂载共享文件。
        </p>
        <div class="more">
          <div class="more1">
            部署简单
          </div>
          <div class="more1 more2">
            安全稳定
          </div>
          <div class="more1 more3">
            服务保障
          </div>
          <div class="more1 more4">
            随时管理
          </div>
        </div>
      </div>
    </div>

    <div class="i2 i3">
      <div class="text1">内网穿透 轻松回内网</div>
      <div class="content">
        <div class="text2">不受网络限制，通过域名访问内网</div>
        <p class="text3">
          自定义域名访问内网服务，支持身份认证、网络认证、设备鉴定、加密传输的安全策略。可适应复杂的网络环境和防火墙，采用自主研发的 CSDPI（Client Service Data Interconnection）大并发请求访问技术，保障快速访问到内网服务。
        </p>
        <div class="more">
          <div class="more1">
            快速部署
          </div>
          <div class="more1 more2">
            安全稳定
          </div>
          <div class="more1 more3">
            可靠保障
          </div>
          <div class="more1 more4">
            低延时
          </div>
        </div>
      </div>
    </div>
    <div class="i4">
      <div class="home-item4-c">
        <div class="t1">异地组网   一台安装服务全网</div>
        <div class="text2">不用每台设备都安装   通过中转连接更多设备</div>
        <div class="t2">
          <div class="title1">点对点组网</div>
          <div class="title2">两端登录全自动组网</div>
          <div class="t2-img t2-img2"></div>
        </div>
        <div class="t2 t2a">
          <div class="title1">全网映射组网</div>
          <div class="title2">将远程局域网映射到本机</div>
          <div class="t2-img"></div>
        </div>
      </div>
    </div>
    <div class="i9">
      <div class="t1">零信任安全保障</div>
      <div class="text2">端对端私有协议  一次一密安全连接</div>
      <div class="t2-c">
        <div class="t2">
          <div class="t3">三重认证</div>
          <div class="t4">用户认证</div>
          <div class="t4">设备认证</div>
          <div class="t4">链路认证</div>
        </div>
        <div class="t2 t2a">
          <div class="t3">动态密钥</div>
          <div class="t4">一次一密</div>
          <div class="t4">即时协商</div>
          <div class="t4">256位加密</div>
        </div>
        <div class="t2 t2b">
          <div class="t3">动态域名</div>
          <div class="t4">即时生成</div>
          <div class="t4">立即生效</div>
          <div class="t4">延时销毁</div>
        </div>
        <div class="t2 t2c">
          <div class="t3">来源限定</div>
          <div class="t4">智能识别</div>
          <div class="t4">攻击阻断</div>
          <div class="t4">风控报警</div>
        </div>
        <div class="t2 t2d">
          <div class="t3">链路保障</div>
          <div class="t4">可信认证</div>
          <div class="t4">加密传输</div>
          <div class="t4">延时关闭</div>
        </div>
      </div>
    </div>
    <div class="i6">
      <div class="home-item6-c">
        <div class="t1">用节点小宝  体验超凡连接</div>
        <div class="t2">智慧互联 易用快速</div>
        <div class="t3">
          十分期待您来体验产品，无论您是远程开发、远程剪辑、远程下载、远程管理。
          节点小宝超凡的互联传输，助您置身快感之中。
        </div>
      </div>
    </div>
    <div class="i10">
      <div class="t1">来自真实用户的评价</div>
      <div class="text2">因卓越的速度，获得了众多用户的喜爱与信赖</div>
      <div class="t2-cc">
        <div class="t2-c">
          <div class="item" v-for="(item, index) in pjArr">
            <img class="item-img" :src="item.imgsrc" />
            <div class="item-t1">{{ item.name }}</div>
            <div class="item-t2">{{ item.pj }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="i12">
      <div class="t1-c">
        <div class="t1">远程访问用小宝，您的远程访问专家。</div>
        <div class="btn" @click="jumpDownload">立即下载</div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/Foot.vue";
import a1 from "../assets/v4/home65.png";
import a2 from "../assets/v4/home38.png";
import a3 from "../assets/v4/home66.png";
import a4 from "../assets/v4/home67.png";
import a5 from "../assets/v4/home68.png";
import a6 from "../assets/v4/home69.png";
import a7 from "../assets/v4/home70.png";
import a8 from "../assets/v4/home71.png";
import a9 from "../assets/v4/home72.png";
import a10 from "../assets/v4/home73.png";
import a11 from "../assets/v4/home74.png";
import a12 from "../assets/v4/home75.png";

export default {
  name: "Home",
  components: {
    Foot,
  },
  data: function () {
    return {
      pjArr: [
        {
          name: "一条咸鱼",
          pj: "最让我觉得厉害的是自定义域名内网转发功能，实在是太便捷了。要知道一个内网穿透就可以让人操作半天，搞一个域名访问又是半天的事。结果小宝这里，我第一次用也只用了1分钟不到。。。完全颠覆了我对这两个操作的固有印象。",
          imgsrc: a1,
        },
        {
          name: "蜡笔",
          pj: "你们的产品太牛逼了，好方便啊，有他域名访问，以后连客户端都不用装了。",
          imgsrc: a2,
        },
        {
          name: "热心市民林先生",
          pj: "节点小宝智能组网速度有点牛，看电影怎么拉都不卡。",
          imgsrc: a3,
        },
        {
          name: "柊下桜",
          pj: "这可能是我一直苦苦追求的理想中的工具！我就想要在外面用笔记本也可以访问家里的samba。",
          imgsrc: a4,
        },
        {
          name: "吴刚",
          pj: "异地组网的功能非常好，NAS一般都支持映射网络驱动器，只要启动节点小宝，远程的NAS就可以当做本机硬盘一样用了。",
          imgsrc: a5,
        },
        {
          name: "Jeemzz",
          pj: "节点小宝的稳定性令人印象深刻，无论是大规模数据传输还是长时间稳定运行，都能保持出色的性能，非常值得信赖。",
          imgsrc: a6,
        },
        {
          name: "Winston",
          pj: "数据安全是我最关心的，节点小宝的点对点安全直连技术让我非常放心。每次使用，都感觉数据传输又快又稳。",
          imgsrc: a7,
        },
        {
          name: "玉宇琼楼",
          pj: "自从用了节点小宝，我的远程工作变得轻松多了！异地组网和内网穿透功能太实用了，真心推荐给大家！",
          imgsrc: a8,
        },
        {
          name: "จุ๊บ惜情จุ๊บ",
          pj: "出差在外，还能随时管理公司的内网资源，这都要归功于节点小宝的异地组网功能。真心感谢节点小宝团队！",
          imgsrc: a9,
        },
        {
          name: "Karl Yau",
          pj: "强烈推荐节点小宝给所有需要远程管理的朋友。它的内网穿透功能真的太强大了，无论在哪里都能轻松访问公司内网。",
          imgsrc: a10,
        },
        {
          name: "起风了",
          pj: "高效、安全、稳定，这是我对节点小宝的评价。它不仅满足了我的工作需求，还给了我超出预期的体验，感谢节点小宝。",
          imgsrc: a11,
        },
        {
          name: "Timymood",
          pj: "相较于其他同类产品，节点小宝的价格非常合理。它的功能和性能都超出了我的预期，而价格却相对亲民。这种高性价比让我感到非常满意。",
          imgsrc: a12,
        },
      ],
      text:"",
      textArray:["开发机","NAS","原生桌面","OA系统","数据库","Docker","共享文件夹","SSH"],
      charIndex:0,
      textArrayIndex:0,
    };
  },
  computed:{
    classComputed:function() {
      return{
        g1:this.textArrayIndex == 1,
        g2:this.textArrayIndex == 2,
        g3:this.textArrayIndex == 3,
        g4:this.textArrayIndex == 4,
        g5:this.textArrayIndex == 5,
        g6:this.textArrayIndex == 6,
        g7:this.textArrayIndex == 7,
      }
    }

  },
  methods: {
    erase:function(){
      let _that = this;
      setTimeout(function(){
        if(_that.charIndex > 0) {
          _that.text = _that.textArray[_that.textArrayIndex].substring(0, _that.charIndex - 1);
          _that.charIndex--;
          _that.erase();
        }else{
          _that.textArrayIndex++;
          if (_that.textArrayIndex >= _that.textArray.length){
            _that.textArrayIndex = 0;
          }
          setTimeout(function () {
              _that.type();
          }, 170 + 1100);
        }
      }, 70)
    },
    type:function(){
      let _that = this;
      setTimeout(function(){
        if(_that.charIndex <  _that.textArray[_that.textArrayIndex].length){
          _that.text += _that.textArray[_that.textArrayIndex].charAt(_that.charIndex);
          _that.charIndex++;
          _that.type();
        }else{
          setTimeout(function () {
            _that.erase();
          }, 1000);
        }
      }, 170);
    },


    jumpDownload: function () {
      this.$router.push({
        path: "download",
      });
    },
  },
  mounted() {
    let _that = this;
    setTimeout(function () {
      _that.type();
    },1000 + 250);
  },
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home {



  .i1{
    height: 900px;
    background-image: url("../assets/v5/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;

    .text1{
      margin-top: 100px;
      font-weight: 900;
      color: #FFFFFF;
      font-size: 48px;
      line-height: 72px;
      -webkit-text-stroke: 1.5px #FFFFFF;
    }
    .text2{
      margin-top: 8px;
      color: #B1B5C4;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .gt{
      position: relative;
      margin-top: 24px;
      height: 54px;
      font-weight: 700;
      .text3{
        position: absolute;
        top: 0;
        left: 42%;
        color: #FFFFFF;
        font-size: 36px;
        line-height: 54px;
      }
      .gradient-text{
        position: absolute;
        top: 0;
        left: 52%;
        background: linear-gradient(45deg, #FEEE88,#32FAED,#007BFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        line-height: 54px;
      }
      .g1{
        background: linear-gradient(45deg, #7EFF90,#FFDDA5,#FF6BFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g2{
        background: linear-gradient(45deg,#FF9D72,#FFDF7C,#FF38C1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g3{
        background: linear-gradient(45deg,#49FBCF,#A6FF82);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g4{
        background: linear-gradient(45deg,#F46844,#FF64F4,#6B94FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g5{
        background: linear-gradient(45deg,#48F9FF,#6B75FF,#F953D7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g6{
        background: linear-gradient(45deg,#AA56FF,#FF6838,#FF8C29);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .g7{
        background: linear-gradient(45deg,#FFFF48,#6BFF8E,#49A7FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

    .btn{
      margin: 0 auto;
      margin-top: 50px;
      width: 160px;
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      background-color: #3772FF;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
    .btn:hover{
      opacity: 0.9;
    }

    .btn-download {
      margin: 0 auto;
      text-align: center;
      margin-top: 77px;
      width: 1280px;
      height: 100px;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.1);
      font-size: 18px;
      box-shadow: rgba(149, 149, 193, 0.1) 0px 4px 24px;
      backdrop-filter: blur(10px);
      .btn-d {
        margin-top: 13px;
        display: inline-block;
        margin-right: 8px;
        width: 170px;
        height: 70px;
        line-height: 70px;
        cursor: pointer;
        text-decoration: none;
        color: #F4F5F6;
        font-weight: 500;

        .btn-img {
          display: inline-block;
          margin-top: 13px;
          margin-right: 6px;
          background-image: url("../assets/v5/nas.svg");
          width: 44px;
          height: 44px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          vertical-align: top;
        }
        .btn-img2 {
          background-image: url("../assets/v5/win.svg");
        }
        .btn-img3 {
          background-image: url("../assets/v5/macOs.svg");
        }
        .btn-img4 {
          background-image: url("../assets/v5/android.svg");
        }
        .btn-img5 {
          background-image: url("../assets/v5/ios.svg");
        }
        .btn-img6 {
          background-image: url("../assets/v5/docker.svg");
        }
        .btn-img7 {
          background-image: url("../assets/v5/openWrt.svg");
        }
      }

      /*.btn-d:hover {*/
      /*  color: #4e4cff;*/
      /*  border-radius: 10px;*/
      /*  background-color: rgba(255,255,255,0.1);*/
      /*  .btn-img1 {*/
      /*    background-image: url("../assets/v4/home46.svg");*/
      /*  }*/
      /*  .btn-img2 {*/
      /*    background-image: url("../assets/v4/home47.svg");*/
      /*  }*/
      /*  .btn-img3 {*/
      /*    background-image: url("../assets/v4/home5.svg");*/
      /*  }*/
      /*  .btn-img4 {*/
      /*    background-image: url("../assets/v4/home48.svg");*/
      /*  }*/
      /*  .btn-img5 {*/
      /*    background-image: url("../assets/v4/home49.svg");*/
      /*  }*/
      /*  .btn-img6 {*/
      /*    background-image: url("../assets/v4/home50.svg");*/
      /*  }*/
      /*  .btn-img7 {*/
      /*    background-image: url("../assets/v4/home51.svg");*/
      /*  }*/
      /*}*/
      .btn-d6 {
        margin-left: 0px;
      }
    }

    .text4{
      margin-top: 60px;
      font-weight: 900;
      font-size: 44px;
      line-height: 56px;
      color: #FFFFFF;
    }
    .text5{
      margin-top: 20px;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      color: #FFFFFF;
    }
    .text6{
      margin-top: 24px;
      line-height: 24px;
      font-size: 20px;
      color: #B1B5C4;
      .text7{
        font-weight: 700;
        color: #ffffff;
      }
    }

  }
  .i2{
    position: relative;
    height: 620px;
    overflow: hidden;
    background-color: #ffffff;
    .text1{
      margin-top: 70px;
      font-size: 40px;
      font-weight: 700;
      color: #1D1F24;
      line-height: 56px;
      text-align: center;
      -webkit-text-stroke: 0.5px #1D1F24;
    }
    .content{
      position: relative;
      margin: 0 auto;
      margin-top:50px;
      padding-top: 32px;
      width: 1280px;

      .text2{
        margin-left: 720px;
        font-weight: 700;
        font-size: 30px;
        line-height: 42px;
      }
      .text3{
        margin-top: 20px;
        margin-left: 720px;
        width: 558px;
        font-size: 18px;
        line-height: 30px;
        color: #585D6C;
      }
      .more{
        margin-top: 22px;
        margin-left: 720px;
        .more1{
          display: inline-block;
          position: relative;
          margin-right: 86px;
          padding-top: 48px;
          font-size: 16px;
          line-height: 20px;
          color: #585D6C;
        }
        .more1::before{
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%,0);
          content: "";
          width: 36px;
          height: 36px;
          background-image: url("../assets/v5/icon1.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .more2::before{
          background-image: url("../assets/v5/icon2.svg");
        }
        .more3::before{
          background-image: url("../assets/v5/icon3.svg");
        }
        .more4::before{
          background-image: url("../assets/v5/icon4.svg");
        }
        .more4{
          margin-right: 0;
        }
      }
    }
    .content::before{
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 640px;
      height: 360px;
      background-image: url("../assets/v5/link.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

  }
  .i3{
    .text1{
      margin-top: 30px;
    }
    .content{
      .text2,.text3,.more{
        margin-left: 0;
      }
    }
    .content::before{
      right: 0;
      left: unset;
      background-image: url("../assets/v5/linksafe.png");
    }
  }
  .i4{
    width: 100%;
    height: 760px;
    background-image: url("../assets/v5/bglinear.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .home-item4-c {
      margin: 0 auto;
      width: 1280px;
      height: 700px;
      overflow: hidden;

      .t1 {
        margin-top: 70px;
        text-align: center;
        line-height: 56px;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        -webkit-text-stroke: 0.5px #ffffff;
      }
      .text2{
        margin-top: 20px;
        font-size: 30px;
        line-height: 42px;
        color: #FFFFFF;
        text-align: center;
      }
      .t2 {
        float: left;
        margin-top: 60px;
        width: 620px;
        height: 420px;
        border-radius: 20px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        text-align: center;
        overflow: hidden;
        .title1 {
          margin-top: 36px;
          font-size: 30px;
          color: #ffffff;
          font-weight: bold;
        }
        .title2 {
          margin-top: 10px;
          font-size: 18px;
          color: #ffffff;
        }
      }
      .t2a {
        margin-left: 40px;
      }
      .t2-img {
        margin: 0 auto;
        margin-top: 63px;
        width: 472px;
        height: 186px;
        background-image: url("../assets/v5/serve2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .t2-img2 {
        margin-top: 70px;
        width: 371px;
        height: 169px;
        background-image: url("../assets/v5/serve.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .i9{
    width: 100%;
    height: 660px;
    overflow: hidden;
    background: linear-gradient(45deg,#F4F5F6,#EAF0FF,#FFF4F0);
    .t1 {
      margin-top: 80px;
      font-size: 40px;
      font-weight: 700;
      color: #1D1F24;
      line-height: 56px;
      text-align: center;
      -webkit-text-stroke: 0.5px #1D1F24;
    }
    .text2{
      margin-top: 20px;
      font-size: 30px;
      line-height: 42px;
      color: #1D1F24;
      text-align: center;
    }
    .t2-c {
      margin: 0 auto;
      margin-top: 58px;
      width: 1280px;
      height: 300px;
      text-align: center;
      .t2 {
        position: relative;
        float: left;
        width: 232px;
        height: 300px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        cursor: pointer;

        .t3 {
          margin-top: 123px;
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: 500;
          color: #1D1F24;
        }
        .t4 {
          font-size: 16px;
          line-height: 30px;
          color: #585D6C;
        }
      }

      .t2::before {
        content: "";
        position: absolute;
        top: 40px;
        left: 88px;
        width: 56px;
        height: 56px;
        background-image: url("../assets/v4/home33.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .t2a::before {
        background-image: url("../assets/v4/home34.svg");
      }
      .t2b::before {
        background-image: url("../assets/v4/home35.svg");
      }
      .t2c::before {
        background-image: url("../assets/v4/home36.svg");
      }
      .t2d::before {
        background-image: url("../assets/v4/home37.svg");
      }
      .t2a,
      .t2b,
      .t2c,
      .t2d {
        margin-left: 30px;
      }
    }
  }
  .i6{
    width: 100%;
    height: 800px;
    background-color: #17171b;

    .home-item6-c {
      margin: 0 auto;
      height: 800px;
      width: 100%;
      overflow: hidden;
      background-image: url("../assets/v5/linksuper.png");
      background-size: 1280px 560px;
      background-position: center bottom;
      background-repeat: no-repeat;

      .t1 {
        margin-top: 70px;
        text-align: center;
        font-size: 40px;
        line-height: 56px;
        font-weight: 700;
        color: #ffffff;
        -webkit-text-stroke: 0.5px #ffffff;
      }
      .t2 {
        margin-top: 12px;
        text-align: center;
        font-size: 32px;
        line-height: 45px;
        font-weight: 700;
        color: #ffffff;
      }
      .t3 {
        margin: 0 auto;
        margin-top: 23px;
        width: 700px;
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
      }
    }
  }
  .i10 {
    width: 100%;
    height: 720px;
    background-color: #ffffff;
    overflow: hidden;
    .t1 {
      margin-top: 80px;
      font-size: 40px;
      font-weight: 700;
      color: #1D1F24;
      line-height: 56px;
      text-align: center;
      -webkit-text-stroke: 0.5px #1D1F24;
    }
    .text2{
      margin-top: 20px;
      font-size: 30px;
      line-height: 45px;
      text-align: center;
    }
    .t2-cc {
      margin-top: 48px;
      position: relative;
      height: 370px;
      width: 100%;
    }
    .t2-cc::before {
      content: "";
      position: absolute;
      width: 272px;
      height: 370px;
      background: linear-gradient(
              90.5deg,
              rgb(255, 255, 255) 0.36%,
              rgba(255, 255, 255, 0) 99.49%
      );
      top: 0px;
      left: 0px;
      z-index: 2;
    }
    .t2-cc::after {
      content: "";
      position: absolute;
      width: 272px;
      height: 370px;
      background: linear-gradient(
              90.5deg,
              rgba(255, 255, 255, 0) 0.36%,
              rgb(255, 255, 255) 99.49%
      );
      top: 0px;
      right: 0px;
      z-index: 2;
    }
    .t2-c {
      width: 4300px;
      /*width: 6048px;*/
      height: 370px;
      animation: 28s linear 0s infinite normal none running move;
      .item {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
        width: 304px;
        height: 370px;
        border-radius: 20px;
        border: 1px solid #e5e6eb;
        overflow: hidden;
        box-sizing: border-box;

        .item-img {
          display: block;
          margin: 0 auto;
          margin-top: 46px;
          width: 80px;
          height: 80px;
        }
        .item-t1 {
          margin-top: 16px;
          font-size: 24px;
          font-weight: bold;
          color: #17171b;
          text-align: center;
        }
        .item-t2 {
          margin: 0 auto;
          margin-top: 16px;
          text-align: center;
          font-size: 16px;
          color: #57576c;
          width: 240px;
        }
      }
    }
    @keyframes move {
      100% {
        transform: translateX(-2100px);
      }
    }
  }
  .i12 {
    width: 100%;
    height: 160px;
    background-image: url("../assets/v5/bgtry.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .t1-c {
      position: relative;
      margin: 0 auto;
      width: 1280px;
      height: 160px;
      overflow: hidden;
      .t1 {
        font-size: 36px;
        line-height: 160px;
        font-weight: 500;
        color: #ffffff;
        -webkit-text-stroke: 0.5px #ffffff;
      }
      .t2 {
        margin-top: 6px;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        color: #ffffff;
      }
      .btn {
        position: absolute;
        top: 58px;
        right: 0;
        width: 140px;
        height: 44px;
        line-height: 44px;
        border-radius: 6px;
        background-color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        color: #3772FF;
        text-align: center;
        cursor: pointer;
      }
    }
  }

}
</style>

