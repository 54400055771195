<template>
  <div id="nav" class="nav bg1">
    <div class="nav-main">
      <router-link to="/">
        <img :src="logo" alt="#" class="logo" />
      </router-link>
      <router-link to="/">
        首页
      </router-link>
      <router-link to="/download">
        产品
      </router-link>
      <a @click="goHelp" href="javascript:;">
        文档
      </a>

<!--      <router-link to="/about">关于我们</router-link>-->
<!--      <a class="download" @click="goHelp" href="javascript:;">帮助中心</a>-->
<!--      <a class="download" @click="goNews" href="javascript:;"> 资讯 </a>-->
<!--      <router-link to="/download"> 下载 </router-link>-->
<!--      <router-link to="/business" class="second-nav"> 购买 </router-link>-->
<!--      <router-link to="/" class="second-nav"> 首页 </router-link>-->


      <router-link class="download-btn" to="/download" >开始使用</router-link>
      <a class="login-btn" href="http://192.168.71.88:8000/login" target="_blank" v-if="false">
        控制台
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data: function () {
    return {
      logo: require("../assets/v5/logo.svg"),
      bg: "",
      navActive: false,
    };
  },
  watch: {
    $route(to, from) {
      let _that = this;
      if (to.path.indexOf("answer") != -1 || to.path.indexOf("help") != -1) {
        _that.navActive = true;
      } else {
        _that.navActive = false;
      }
    },
  },
  props: {},
  methods: {
    goHelp: function () {
      window.location.href = "/helpcenter/";
    },
    goNews: function () {
      window.location.href = "/news";
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #e8eaf3;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);

  .nav-main {
    margin: 0 auto;
    width: 1280px;
  }
  .nav-main:after {
    content: "";
    display: block; /* 设置添加子元素为块级元素 */
    height: 0; /* 设置添加的子元素的高度0 */
    visibility: hidden; /* 设置添加子元素看不见 */
    clear: both; /* 设置clear：both */
  }

  a {
    float: left;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px;
    color: #1D1F24;
    text-decoration: none;
    height: 70px;
    line-height: 70px;
    vertical-align: top;
    cursor: pointer;
  }
  a:hover {
    color: #3772FF;
  }
  a.router-link-exact-active {
    color: #3772FF;
  }





  a:first-child {
    padding: 0;
    margin-right: 20px;
    .logo {
      margin-top: 18px;
      width: 140px;
      height: 32px;
    }
  }

  .login-btn{
    float: right;
    color: #3772FF;
  }

  .download-btn{
    float: right;
    margin-top: 15px;
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #3772FF;
    border-radius: 6px;
    font-size: 16px;
    color: #FFFFFF !important;
    font-weight: 500;
  }
  .download-btn:hover{
    color: #FFFFFF;
    opacity: 0.9;
  }




}
.bg1 {
  background: rgba(255, 255, 255, 0.9);
}
</style>

