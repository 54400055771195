<template>
  <div class="foot">
    <div class="foot-t1-c">
      <div class="foot-img"></div>
      <div class="foot-t1">
        <div class="t1">下载</div>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?device=nas&brand=synology">Nas</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?device=windows">Windows</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?device=mac">macOS</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?client=Android">Android</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?client=ios">iOS</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?device=docker">Docker</a>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/download?device=openwrt">OpenWrt</a>
      </div>
      <div class="foot-t1">
        <div class="t1">支持</div>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/help">文档</a>
        <a class="t2" target="_blank" href="https://iepose.com/news">资讯</a>
      </div>
      <div class="foot-t1">
        <div class="t1">关于节点小宝</div>
        <a class="t2" target="_blank" href="https://iepose.com/jdxb/pc/about">关于我们</a>
      </div>
      <div class="foot-t1 foot-follow">
        <div class="t1">关注我们</div>
        <div class="t2" @mouseover="wxqrcodeOver" @mouseleave="wxqrcodeLeave">微信公众号</div>
        <div class="t2" @mouseover="biOver" @mouseleave="biLeave">bilibili</div>
        <div class="t2" @mouseover="redbookOver" @mouseleave="redbookLeave">小红书</div>
        <div class="t2" @mouseover="douyinOver" @mouseleave="douyinLeave">抖音</div>
        <div class="wx" v-if="wxqrcode2 == 1"></div>
        <div class="wx bi" v-if="bi == 1"></div>
        <div class="wx redbook" v-if="redbook == 1"></div>
        <div class="wx douyin" v-if="douyin == 1"></div>
      </div>
      <div class="foot-t1 foot-last">
        <div class="t1">联系我们</div>
        <div class="qrcode"></div>
      </div>
      <div class="copyright">@2024 版权所有 深圳市玩物科技有限公司 粤ICP备18147482号-1粤公网安备 44030502004455号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foot",
  data: function () {
    return {
      wxqrcode2:0,
      bi:0,
      redbook:0,
      douyin:0
    };
  },
  methods:{
    douyinOver:function(){
      this.douyin = 1;
    },
    douyinLeave:function(){
      this.douyin = 0;
    },
    redbookOver:function(){
      this.redbook = 1;
    },
    redbookLeave:function(){
      this.redbook = 0;
    },
    biOver:function(){
      this.bi = 1;
    },
    biLeave:function(){
      this.bi = 0;
    },
    wxqrcodeOver:function(){
      this.wxqrcode2 = 1;
    },
    wxqrcodeLeave:function(){
      this.wxqrcode2 = 0;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.foot{
  height: 440px;
  background-color: #F6F8FF;
  .foot-t1-c{
    position: relative;
    margin: 0 auto;
    padding-top: 46px;
    width: 1280px;
    height: 440px;
    box-sizing: border-box;
    .foot-img{
      float: left;
      width: 140px;
      height: 32px;
      background-image: url("../assets/v5/logo.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .foot-t1{
      margin-left: 140px;
      float: left;
      .t1{
        font-size: 18px;
        font-weight: 500;
        color: #17171B;
      }
      .t2{
        display: block;
        margin-top: 20px;
        color: #57576C;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
      }
      .qrcode{
        margin-top: 20px;
        width: 120px;
        height: 120px;
        background-image: url("../assets/v4/home44.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    .foot-last{
      margin-left: 0;
      float: right;
    }
    .foot-follow{
      position: relative;

      .wx{
        position: absolute;
        z-index: 1;
        top: -90px;
        left: -20px;
        width: 120px;
        height: 120px;
        border-radius: 6px;
        box-shadow: 0px 2px 6px 0px #E8ECF9;
        background-image: url("../assets/v4/home64.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .bi{
        top: -50px;
        background-image: url("../assets/v4/home78.png");
      }
      .redbook{
        top: -6px;
        background-image: url("../assets/v4/home79.png");
      }
      .douyin{
        top: 36px;
        background-image: url("../assets/v4/home80.png");

      }
    }
    .copyright{
      position: absolute;
      bottom: 28px;
      left: 0;
      font-size: 14px;
      color: #9090A2;
    }
  }
}
</style>
