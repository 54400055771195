import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Telescope from '../views/Telescope.vue'
import Share from '../views/Share.vue'
import About from '../views/About.vue'
// import Buy from '../views/Buy.vue'
// import Download2 from '../views/DownloadV2.vue'
// import Download from '../views/Download.vue'
// import Download from '../views/DownloadV4.vue'
import Download from '../views/DownloadV5.vue'
import Support from '../views/Support.vue'
import Support1 from '../views/Support1.vue'
import Support2 from '../views/Support2.vue'
import Support3 from '../views/Support3.vue'
import Support4 from '../views/Support4.vue'
import Support5 from '../views/Support5.vue'
import Support6 from '../views/Support6.vue'
import Answer from '../views/Answer.vue'
import QueryTwmDevice from '../views/QueryTwmDevice.vue'
import QueryWltDevice from '../views/QueryWltDevice.vue'
import QueryQhDevice from '../views/QueryQhDevice.vue'
import Help from '../views/Help.vue'
import store from '../store';



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '节点小宝远程访问专家--内网穿透 异地组网 智慧互联'
    },
  },
  {
    path: '/telescope',
    name: 'Telescope',
    component: Telescope,
    meta: {
      title: '小宝望远镜'
    },
  },
  {
    path: '/share',
    name: 'Share',
    component: Share,
    meta: {
      title: '共享小宝驿站'
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: {
      title: '下载节点小宝'
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: '关于节点小宝'
    },
  },
  // {
  //   path: '/business',
  //   name: 'Buy',
  //   component: Buy,
  //   meta: {
  //     title: '节点小宝-远程访问理想伴侣'
  //   },
  // },
  // {
  //   path: '/download2',
  //   name: 'Download',
  //   component: Download2,
  //   meta: {
  //     title: '下载'
  //   },
  // },
  {
    path: '/answer',
    name: 'Answer',
    component: Answer,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      title: '帮助中心-节点小宝'
    },
  },
  {
    path: '/twm/device/query',
    name: 'TwmDeviceQuery',
    component: QueryTwmDevice,
    meta: {
      title: ''
    },
  },
  {
    path: '/wlt/device/query',
    name: 'WltDeviceQuery',
    component: QueryWltDevice,
    meta: {
      title: ''
    },
  },
  {
    path: '/qh/device/query',
    name: 'QhDeviceQuery',
    component: QueryQhDevice,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/1',
    name: 'Support',
    component: Support1,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/2',
    name: 'Support',
    component: Support2,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/3',
    name: 'Support',
    component: Support3,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/4',
    name: 'Support',
    component: Support4,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/5',
    name: 'Support',
    component: Support5,
    meta: {
      title: '帮助'
    },
  },
  {
    path: '/support/6',
    name: 'Support',
    component: Support6,
    meta: {
      title: '帮助'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? "/jdxb/pc/" : process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
  setTimeout(function () {
    store.dispatch('sendUserlog', ["to", to.path]);
  }, 500);
});

export default router
